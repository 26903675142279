<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-allelselaw.png'"
        :letter="'a'"
        :selected="details.distribution_all_die_dont_care"
        :option="'Follow the \'Laws of Intestacy\''"
        @selected="saveToFollowLaws"
      >
        <template v-slot:text>
          <div>
            If all of the beneficiaries that you have named pass away before
            you, it is your closest blood relatives as defined by the law of
            intestacy who will inherit.
          </div>
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/info-box1.png'"
        :letter="'b'"
        :selected="details.distribution_all_die_backup_table"
        :option="'Name \'Substitute\' beneficiaries'"
        @selected="saveToSubstitute"
      >
        <template v-slot:text>
          <div v-html="toSubstituteText"></div>
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'DistributionAllFail',
  mixins: [willStringHelpers, TextFormat],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge
  },
  props: [],
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    // if (this.details.distribution_all_die_dont_care) this.removeAllBackupBeneficiaries()
    next()
  },
  computed: {
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.peoplePrimary) number += this.peoplePrimary.length
      if (this.groupsPrimary) number += this.groupsPrimary.length
      return number + 1
    },
    group() {
      return this.$store.getters.groups.filter(
        (group) =>
          String(group.group_name).toLowerCase() ===
          String(this.groupName).toLowerCase()
      )[0]
    },
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    groups() {
      return this.$store.getters.groups
    },
    charities() {
      return this.$store.getters.charities
    },
    details() {
      return this.$store.getters.details
    },
    peoplePrimary() {
      return this.$store.getters.peopleDistributionPrimary
    },
    groupsPrimary() {
      return this.$store.getters.groupsDistributionPrimary
    },
    charitiesPrimary() {
      return this.$store.getters.charitiesDistributionPrimary
    },
    peopleBackup() {
      return this.people.filter((person) => person.distribution_backup)
    },
    groupsBackup() {
      return this.groups.filter((group) => group.distribution_backup)
    },
    charitiesBackup() {
      return this.charities.filter((charity) => charity.distribution_backup)
    },
    isValidated() {
      if (
        this.details &&
        (this.details.distribution_all_die_dont_care ||
          this.details.distribution_all_die_backup_table)
      )
        return true
      return false
    },
    forwardTo() {
      if (this.details && this.details.distribution_all_die_dont_care) {
        return '/distribution/minors'
      }
      return '/distribution/substitute_beneficiaries'
    },
    backTo() {
      if (this.groupsPrimary && this.groupsPrimary.length) {
        return `/distribution/primary_group/${
          this.groupsPrimary[this.groupsPrimary.length - 1].group_name
        }/${this.groupsPrimary[this.groupsPrimary.length - 1].id}`
      } else if (this.peoplePrimary && this.peoplePrimary.length)
        return `/distribution/primary_person/${
          this.peoplePrimary[this.peoplePrimary.length - 1].full_name
        }/${this.peoplePrimary[this.peoplePrimary.length - 1].id}`
      return '/distribution/beneficiaries'
    },
    toSubstituteText() {
      return (
        'Add details of friends, family or charities who will benefit (If ' +
        this.listPartnerPeopleGroupsCharities(
          this.partner && this.partner.distribution_sole_primary
            ? this.partner
            : null,
          this.peoplePrimary,
          this.groupsPrimary,
          this.charitiesPrimary,
          ' and ',
          true
        ) +
        ' etc, have passed away before you)'
      )
    }
  },
  data() {
    return {
      groupName: null,
      show: {
        intro: true,
        addModal: false
      },
      loading: false,
      heading: 'Who should benefit if all else fails?',
      subHeading:
        "Wills sometimes contain a 'calamity' or 'common disaster' provision; this can help where a family regularly travel together for " +
        'example.  If you prefer, you can choose to have your estate distributed according to the laws of intestacy.'
    }
  },
  methods: {
    saveToFollowLaws() {
      this.$store.commit('details', {
        distribution_all_die_dont_care: true,
        distribution_all_die_backup_table: false
      })
    },
    saveToSubstitute() {
      this.$store.commit('details', {
        distribution_all_die_dont_care: false,
        distribution_all_die_backup_table: true
      })
    },
    removeAllBackupBeneficiaries() {
      this.peopleBackup.forEach((person) => {
        person.distribution_backup_percentage = ''
        person.distribution_backup = false
        this.$store.commit('personAdd', person)
      })

      this.groupsBackup.forEach((group) => {
        group.distribution_backup_percentage = ''
        group.distribution_backup = false
        this.$store.commit('groupAdd', group)
      })

      this.charitiesBackup.forEach((charity) => {
        charity.distribution_backup_percentage = ''
        charity.distribution_backup = false
        this.$store.commit('charityAdd', charity)
      })

      this.$store.dispatch('savePeople')
      this.$store.dispatch('saveGroups')
      this.$store.dispatch('saveCharities')
    }
  }
}
</script>
